require = {
	baseUrl : (window.location.protocol == "http:"||window.location.protocol == "https:")?"/js/":"js/",
	i18nSupportedLanguages :{
		"root": true,
		"en": true,
		"fr": true
	}, //CUSTOM PROPERTIES
	paths : {
		// jquery : "libs/jquery/jquery_1_9_1",
		jquery : "libs/jquery/jquery-1.10.1",
		//jquery :"libs/jquery/jquery-3.2.1.min",
		//jquery : "libs/jquery/jquery-1.11.2",
		// jquerymobile : "libs/jquery/jquery.mobile-1.3.2",
		'jquerymobile.config' : 'libs/jquery/jquerymobile.config',
		jquerymobile : "libs/jquery/jquery.mobile-1.4.0",
		//jquerymobile : "libs/jquery/jquery.mobile.custom.min"
		//jquerymobile : "libs/jquery/jquery.mobile-1.4.5",
		// jquerymobile : "libs/jquery/jquery.mobile-1.4.0-rc.1",
		jquery_rdio : "libs/rdioweb/jquery.rdio",
		//youtube_iframe_api : "libs/youtubechromeless/youtube.iframe_api",
		underscore : "libs/underscore/underscore",
		curl : "libs/curl/curl",
		backbone : "libs/backbone/backbone",
		fastclick : "libs/fastclick/fastclick",
		iscroll : 'libs/iscroll/iscroll',
		dropzonejs : "libs/dropzonejs/dropzone",
		owlcarousel : 'libs/owlcarousel/owl.carousel',
		swiper : 'libs/swiper/swiper.jquery',
		drop : 'libs/drop/drop.min',
		tether: 'libs/tether/tether.min',
		jqueryembedly : 'libs/jqueryembedly/jquery.embedly.min',
		jquerypreview : 'libs/jquerypreview/jquery.preview.min',
		jqueryscrollstop : 'libs/jqueryscrollstop/jquery.scrollstop',
		jquerylazyload : 'libs/jquerylazyload/jquery.lazyload',
		jqueryvisible : 'libs/jqueryvisible/jquery.visible',
		jqueryhoverintent : 'libs/jqueryhoverintent/jquery.hoverIntent.minified',
		notifyjs : 'libs/notifyjs/notify',
		moment : 'libs/momentjs/moment',
		// promise : 'libs/promises/promise-0.1.1',
		rsvp : 'libs/rsvp/rsvp-latest.amd',
		cordova : "../cordova.js",
		hammerjs: "libs/hammerjs/hammer",
		chaplin : "libs/chaplinjs/chaplin",
		youtubeAuth : "libs/youtubedata/auth",
		youtubeAuth : "libs/youtubedata/auth",
		templates : '../templates',
		typeahead : "libs/typeahead/bootstrap-typeahead",
		materializecss : "libs/materialize/materialize",
		nouislider : "libs/materialize/nouislider",
		mention : "libs/mention/mention",
		positionSticky : "libs/fixedsticky/fixedsticky",
		progressBar : "libs/progressbar/progressbar",
		velocity : "libs/velocity/velocity",
		Pusher: 'https://js.pusher.com/4.3/pusher.min',
		"gapi": "https://apis.google.com/js/client",
		"gapiNew": "https://accounts.google.com/gsi/client?noext",
		"youtubeIframe" : "https://www.youtube.com/iframe_api?noext",
		'facebook': 'https://connect.facebook.net/en_US/sdk',
		"stripe": "https://js.stripe.com/v3/?noext",

	},
	urlArgs : function(id, url) {
		var args = null;
		if(url === "https://apis.google.com/js/client.js"){
			args = "onload=loadGAPIClientInterfaces";
		}
		if(!args){
			//console.log("url1 "+ url);
			return window.trebbleAppVersion && !window.waitForCordovaToLoad? "?bust="+window.trebbleAppVersion: "?";
		}else{
			//console.log("url2 "+ url);
			return (url.indexOf('?') === -1 ? '?' : '&') + args;
		}
	},
	waitSeconds : 50,
	shims : {

		"backbone" : {
			deps : [ 'underscore', 'jquery' ],
			exports : 'Backbone'
		},
		"chaplin" : {
			deps : [ 'backbone', 'underscore', 'jquery' ],
			exports : 'Chaplin'
		},
		"underscore" : {
			exports : '_'
		},

		"jquery" : {
			exports : "$"
		},
		
		"jquerymobile.config" : ["jquery"],

		"jquerymobile" : {
			deps : [ "jquery" , "jquerymobile.config"]
		},
		
		"typeahead" : {
			deps : [ "jquery"]
		},
		"drop":{
			deps : ["tether"]
		},
		"jquery.velocity" :[ "jquery"],

		"materializecss" : {
			deps : [  "jquery.velocity"]
		},
		"nouislider" :{
			deps : [  "materializecss"]
		},
		"mention" : {
			deps : [ "jquery", "typeahead"]
		},
		
		"positionSticky": {
			deps : [ "jquery"]
		},
		"owlcarousel": {
			deps : [ "jquery"]
		},
		"jqueryembedly": {
			deps : [ "jquery"]
		},
		"jqueryhoverintent": {
			deps : [ "jquery"]
		},
		"jquerypreview": {
			deps : [ "jquery"]
		},
		"jqueryscrollstop": {
			deps : [ "jquery"]
		},
		"jquerylazyload": {
			deps : [ "jquery"]
		},
		"jqueryvisible": {
			deps : [ "jquery"]
		},
		'notifyjs': {
			deps : [ "jquery"]
		},
		'facebook' : {
			exports: 'FB'
		},
		'stripe': {
			exports: 'Stripe' 
		}
	}

};
